const REG_DATA = [
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "locked": false,
      "approved": true,
      "created": "2016-12-22T07:46:34.000",
      "expires": "3024-03-12T08:46:34.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "type": "INDIVIDUAL",
      "consumer": {
        "emanApplicationName": "IAN REGISTRATION"
      },
      "consumerKey": "f32d948b0d8g4a608aa8ec81da6b9jh6",
      "consumerSecret": "XyKjWzfLq3ffQBdTh5LJNXfPjeaarH2G",
      "provider": {
        "emanApplicationName": "CDETSNG-WSAPI-PRD"
      },
      "description": "Testing",
      "parentKey": "ad51c885895540a3b3d0424fd1abc1f2"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "locked": true,
      "approved": true,
      "created": "2016-01-02T07:46:34.000",
      "expires": "2024-03-17T07:46:34.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "type": "INDIVIDUAL",
      "consumer": {
        "emanApplicationName": "IAN REGISTRATION"
      },
      "consumerKey": "f02c848a0d8b4a308aa8ec81da6b9dc8",
      "consumerSecret": "UoMjSzfLq3ffQBdTh5LJNXfYcaodrT8F",
      "provider": {
        "emanApplicationName": "CDETSNG-WSAPI-PRD"
      },
      "description": "Testing",
      "parentKey": "ad51c885895540a3b3d0-424fd1abc1f2"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "locked": false,
      "approved": true,
      "created": "2015-12-22T07:46:34.000",
      "expires": "2024-03-10T08:46:34.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "type": "INDIVIDUAL",
      "consumer": {
        "emanApplicationName": "IAN REGISTRATION"
      },
      "consumerKey": "g55hP48a4t9c4a308aa8b9dc8ec81da6",
      "consumerSecret": "QBdTh5caoUoMjSzfLq3ffdrT8FLJNXfY",
      "provider": {
        "emanApplicationName": "CDETSNG-WSAPI-PRD"
      },
      "description": "Testing",
      "parentKey": "ad51c885895540a3b3d0424fd1abc1f2"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        },
        {
          "cecId": "amuppura"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "ACCEPTOWNERSHIP",
          "incremental": false
        }
      ],
      "pendingProviderApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "APPROVEREGISTRATION",
          "incremental": false
        }
      ],
      "locked": true,
      "approved": true,
      "created": "2016-06-22T07:49:53.000",
      "expires": "2024-03-06T07:49:53.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "type": "MASQUERADING",
      "consumer": {
        "emanApplicationName": "IANS MASQUERADE"
      },
      "consumerKey": "3f62ef4ab3c14496a1ef2f881131c2e5",
      "consumerSecret": "bG34FqfhPcWmURY4UVcKbC7DAqArAJvz",
      "provider": {
        "emanApplicationName": "CDETSNG-WSAPI-PRD"
      },
      "description": "Testing masq",
      "parentKey": "0fcd9fe483594ef5846ed30e5c08ce08"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "NOTREVIEWED",
          "incremental": false
        }
      ],
      "locked": false,
      "approved": false,
      "created": "2015-12-22T08:32:54.000",
      "expires": "3024-01-07T08:32:54.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "pendingOwners": {
        "owner": [
          "amuppura"
        ]
      },
      "type": "PROVIDER",
      "consumer": {
        "emanApplicationName": "IAN PROVIDER"
      },
      "consumerKey": "021069023c354c5ea75aa542aa28094b",
      "consumerSecret": "Ti10fUyZjsm0mc0J1AcST9Dwdo6kfBzo",
      "provider": {
        "emanApplicationName": "COAR-PRD"
      },
      "description": "testing",
      "parentKey": "b2ca4b6847c04acfaba46c6eb329778c"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "NOTREVIEWED",
          "incremental": false
        }
      ],
      "locked": false,
      "approved": false,
      "created": "2015-12-22T08:32:54.000",
      "expires": "2024-01-19T08:32:54.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "pendingOwners": {
        "owner": [
          "amuppura"
        ]
      },
      "type": "PROVIDER",
      "consumer": {
        "emanApplicationName": "IAN PROVIDER"
      },
      "consumerKey": "3c35021069024c5ea542aa28094ba75a",
      "consumerSecret": "jsm0mo6kfBzocTi10fUyZ0J1AcST9Dwd",
      "provider": {
        "emanApplicationName": "COAR-PRD"
      },
      "description": "testing",
      "parentKey": "b2ca4b6847c04acfaba46c6eb329778c"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "NOTREVIEWED",
          "incremental": false
        }
      ],
      "locked": false,
      "approved": false,
      "created": "2015-12-22T08:32:54.000",
      "expires": "3024-05-11T08:32:54.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "pendingOwners": {
        "owner": [
          "amuppura"
        ]
      },
      "type": "PROVIDER",
      "consumer": {
        "emanApplicationName": "IAN PROVIDER"
      },
      "consumerKey": "3a21a5504c5e094ba7542aa283c06902",
      "consumerSecret": "0fUycTi11AcST9Z0JDwdjsm0mo6kfBzo",
      "provider": {
        "emanApplicationName": "COAR-PRD"
      },
      "description": "testing",
      "parentKey": "fa977ba4b2ca4b6847c04ac6c6eb328c"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "NOTREVIEWED",
          "incremental": false
        }
      ],
      "locked": false,
      "approved": false,
      "created": "2015-12-22T08:32:54.000",
      "expires": "3024-05-15T08:32:54.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "pendingOwners": {
        "owner": [
          "amuppura"
        ]
      },
      "type": "PROVIDER",
      "consumer": {
        "emanApplicationName": "IAN PROVIDER"
      },
      "consumerKey": "2aa3c35a5404c285e094ba75a2106902",
      "consumerSecret": "fwdUyZ0JcTi101AcST9Djsm0mo6kfBzo",
      "provider": {
        "emanApplicationName": "COAR-PRD"
      },
      "description": "testing",
      "parentKey": "4b6847cb2ca04acfaba46c6eb329778c"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "NOTREVIEWED",
          "incremental": false
        }
      ],
      "locked": false,
      "approved": false,
      "created": "2015-12-22T08:32:54.000",
      "expires": "3024-05-22T08:32:54.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "pendingOwners": {
        "owner": [
          "amuppura"
        ]
      },
      "type": "PROVIDER",
      "consumer": {
        "emanApplicationName": "IAN PROVIDER"
      },
      "consumerKey": "ae094ba75a21069025452aa283c3504c",
      "consumerSecret": "fUyZcST9DcTi10w0J1Adjsm0mo6kfBzo",
      "provider": {
        "emanApplicationName": "COAR-PRD"
      },
      "description": "testing",
      "parentKey": "c04acfaba46c6eb32b2ca4b68479778c"
    },
    {
      "owner": [
        {
          "cecId": "ikleinfe"
        }
      ],
      "pendingOwnerApprovals": [
        {
          "cecId": {
            "cecId": "amuppura"
          },
          "type": "NOTREVIEWED",
          "incremental": false
        }
      ],
      "locked": false,
      "approved": false,
      "created": "2015-12-22T08:32:54.000",
      "expires": "3021-05-29T08:32:54.000",
      "hasOwnershipToAccept": false,
      "hasOwnerToapprove": false,
      "pendingOwners": {
        "owner": [
          "amuppura"
        ]
      },
      "type": "PROVIDER",
      "consumer": {
        "emanApplicationName": "IAN PROVIDER"
      },
      "consumerKey": "98mkfw1a75a21069025452aa283c3504c",
      "consumerSecret": "0om2lhT9DcTi10w0J1Adjsm0mo6kfBzo",
      "provider": {
        "emanApplicationName": "COAR-PRD"
      },
      "description": "testing",
      "parentKey": "1kiu35aba46c6eb32b2ca4b68479778c"
    }

  ]

  export default REG_DATA;